<template>
  <Nav no-top />
  <div class="max-w-450 mx-auto"><RouterView /></div>
  <div class="w-full text-center">
    <div>
      <a rel="noopener noreferrer" href="/nav">历届人气投票结果</a>&ensp;
      <a target="_blank" rel="noopener noreferrer" href="https://jq.qq.com/?k=0BnkgDKx">反馈问题</a>
    </div>
    <div class="quicksand">&copy; Copyright 2022 THBWiki, VoileLabs. Licensed under GPL-3.0.</div>
  </div>
</template>
